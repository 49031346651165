@media (max-width: @screen-md-max) {
	div.section-header {
		.navbar {
			.navbar-collapse {
				.navbar-nav {
					li {

						a,
						button {
							padding: 0 8px;
							font-size: 13px;
						}
					}
				}
			}
		}
	}

	div.bezpieczne-mycie-section-content {
		.owl-carousel {
			.item {
				.slider-content {
					.content-image {
						min-width: 320px;
						max-width: 320px;
						height: 320px;
					}
				}
			}
		}
	}
}

@media (max-width: 1100px) {
	div.section-main-carousel {
		.pulsating-circle {
			position: absolute;
			top: 67px;
			left: 30%;
		}

		.main-carousel-flexbox {
			flex-direction: column-reverse;

			.flexbox-car {
				margin-top: 30px;
				width: 100%;
			}

			.flexbox-description {
				padding-left: 0;
				width: 100%;
				text-align: center;

				.description-box {
					&.active {
						opacity: 0;
					}

					&.active-mobile {
						opacity: 1;
					}

					.nav-tabs {
						justify-content: center;
					}

					.button-configurator,
					.arrow-configurator {
						display: none;
					}
				}
			}
		}

		.button-configurator-wrapper {
			display: flex;
		}
	}
}

@media (max-width: @screen-sm-max) {
	div.section-header {
		.navbar {
			.container {
				display: block;
			}

			.navbar-collapse {
				padding-left: 15px;
				padding-right: 15px;
				box-shadow: none;
				border: none;

				.navbar-nav {
					li {
						height: auto;
						text-align: right;

						a,
						button {
							width: 100%;
							padding: 10px 20px;
							display: block;
							text-align: right;
						}

						&.dropdown {
							.dropdown-backdrop {
								display: none;
							}

							.dropdown-menu {
								margin-top: 0;
								border: none;
								position: static;
								float: none;
							}
						}

						&.active,
						&:hover,
						&.open {

							a,
							button {
								background: none;
								color: #414141;
							}
						}
					}
				}
			}

			.navbar-toggle {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
			}
		}
	}

	div.section-gtechniq {
		.gtechniq-flexbox {
			flex-direction: column;

			.flexbox-product {
				width: 100%;
				max-width: 200px;
			}

			.flexbox-description {
				width: 100%;
				text-align: center;
			}

			.flexbox-image {
				margin: 50px 0;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				img {
					margin-top: 0px;
					width: 30%;

					&:nth-child(1) {
						margin-top: 0;
					}
				}
			}
		}

		&.section-gtechniq-subpage {
			padding: 20px 0;

			.gtechniq-flexbox {
				flex-direction: row;
				justify-content: space-between;

				.flexbox-description {
					width: auto;
					text-align: left;

					h2 {
						font-size: 24px;
					}

					p {
						display: none;
					}
				}

				.flexbox-image {
					margin: 0;
					width: auto;
					min-width: 150px;
					max-width: 150px;
					display: block;

					img {
						width: 100%;
					}
				}
			}
		}
	}

	div.section-car-wash {
		.car-wash-flexbox {
			flex-direction: column;

			.flexbox-service {
				width: 100%;
				max-width: 500px;

				.iframe-wrapper {
					padding-bottom: 57%;
				}
			}

			.flexbox-cta {
				width: 100%;
			}
		}
	}

	div.certyfikaty-section-content {
		.content-flexbox {
			.flexbox-service {
				width: 31%;
			}
		}
	}

	div.oferta-section-content {
		.content-flexbox {
			flex-direction: column;

			.flexbox-body {
				width: 100%;
				text-align: center;
			}

			.flexbox-sidebar {
				width: 100%;

				.button-configurator {
					display: none;
				}

				ul {
					margin-top: 0;
				}
			}
		}

		&.kategoria {
			.content-flexbox {
				flex-direction: column-reverse;

				.flexbox-sidebar {
					.sidebar-button {
						display: block;
					}

					.sidebar-button-close {
						display: block;
					}

					.sidebar-inner {
						position: fixed;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						overflow-y: scroll;
						background: #fff;
						z-index: 555;
						opacity: 0;
						visibility: hidden;
						transition: all 0.3s;

						.heading {
							display: none;
						}

						.category-nav {
							display: flex;
							align-items: center;
							justify-content: center;
							flex-direction: column;
							text-align: center;
							min-height: 100%;
							padding: 50px;

							li {
								margin-bottom: 20px;

								&:before {
									content: none;
								}
							}
						}

						&.active {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}
	}

	div.produkt-section-content {
		.content-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-sidebar {
				min-width: 240px;
				max-width: 240px;
				height: 240px;
			}

			.flexbox-body {
				padding-left: 0;
			}
		}
	}

	div.bezpieczne-mycie-section-content {
		.owl-carousel {
			.item {
				.step {
					margin: 30px 0;
					justify-content: center;
				}

				.slider-content {
					flex-direction: column;

					.content-image {
						min-width: 360px;
						max-width: 360px;
						height: 360px;
					}

					.content-description {
						padding-left: 0;
					}
				}
			}
		}
	}

	div.section-footer {
		.footer-flexbox {
			flex-direction: column;
			align-items: center;
			text-align: center;

			.flexbox-service {
				margin-top: 30px;

				&.info {
					max-width: 100%;
				}
			}
		}
	}
}

@media (max-width: @screen-xs-max) {
	div.section-portfolio {
		.portfolio-flexbox {
			.flexbox-service {
				width: 47%;

				.name {
					margin-top: 15px;
					font-size: 16px;
				}

				.more {
					margin-top: 10px;
				}
			}
		}

		&.portfolio-subpage {
			.portfolio-flexbox {
				.flexbox-single-realization {
					width: 100%;
					order: 3;
				}

				.flexbox-service {
					width: 100px;
				}
			}
		}
	}

	div.certyfikaty-section-content {
		.content-flexbox {
			.flexbox-service {
				width: 47%;
			}
		}
	}

	div.produkt-section-content {
		.description-flexbox {
			flex-direction: column;

			.ck-editor-text {
				width: 100%;
			}
		}
	}

	div.autodetailing-section-content {
		.content-description {
			.content-flexbox {
				flex-direction: column;

				.flexbox-service {
					width: 100%;
					text-align: center;

					img {
						max-width: 300px;
					}
				}
			}
		}

		.media-flexbox {
			flex-direction: column;

			.flexbox-service {
				max-width: 500px;
				width: 100%;
			}
		}

		.content-buttons {
			justify-content: center;
		}
	}

	div.myjnia-section-content {
		padding: 50px 0;

		.section-heading {
			text-align: center;

			h2 {
				font-weight: 300;
				font-size: 26px;
				color: #999;

				small {
					font-size: 13px;
					color: #999;
					opacity: 0.6;
				}
			}
		}

		.content-flexbox {
			.flexbox-body {
				width: 100%;
			}

			.flexbox-sidebar {
				display: none;
			}
		}
	}

	div.bezpieczne-mycie-section-content {
		padding-top: 90px;

		.owl-carousel {
			.item {
				.step {
					flex-direction: column;

					button {
						margin: 20px 0 0 0;
					}
				}

				.slider-content {
					.content-image {
						min-width: 240px;
						max-width: 240px;
						height: 240px;
					}
				}
			}

			.owl-controls {
				width: 100%;
				position: absolute;
				top: -60px;

				.owl-nav {
					margin-top: 0;

					.owl-prev,
					.owl-next {
						font-size: 9px;
					}
				}

				.owl-dots {
					display: none !important;
				}
			}
		}
	}

	div.kontakt-section-content {
		.content-flexbox {
			flex-direction: column;

			.flexbox-service {
				text-align: center;
				white-space: normal;
			}

			.flexbox-form {
				padding-left: 0;
				text-align: center;
			}
		}
	}

	div.konfigurator-section-content {
		.content-summary-box {
			margin-top: 30px;
			position: relative;
			right: auto;
			top: auto;
			padding: 20px;
			opacity: 1;
			transition: all 0.3s;
			border-radius: 5px;
			text-align: center;
			border-right: none;
		}

		.type-flexbox {
			.flexbox-service {
				width: 47%;

				.name {
					margin-top: 15px;
					font-size: 16px;
				}

				.more {
					margin-top: 10px;
				}
			}

			&.type-flexbox-products {
				.flexbox-service {
					width: 43%;

					img {
						max-height: 150px;
					}
				}
			}
		}

		.addition-flexbox {
			flex-direction: column;
			text-align: center;

			.flexbox-image {
				width: 100%;
				height: 150px;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;

				img {
					width: 100%;
				}
			}

			.flexbox-price {
				padding: 20px;
				width: 100%;

				.label-price {
					margin-top: 15px;
					display: block;
				}
			}

			.flexbox-description {
				width: 100%;
			}
		}
	}

	div.konfigurator-podsumowanie-section-content {
		.content-flexbox {
			flex-direction: column;

			.flexbox-service {
				text-align: center;
				white-space: normal;
			}

			.flexbox-form {
				padding-left: 0;
				text-align: center;
			}
		}
	}
}

@media (max-width: 500px) {
	.button-configurator {
		.configurator-image {
			height: 50px;
			width: 50px;

			img {
				width: 30px;
				position: relative;
				z-index: 111;
			}
		}

		.configurator-text {
			margin-left: -11px;
			padding: 10px 15px 10px 15px;

			&:after {
				right: -32px;
				border-width: 33px 33px 0 0;
			}

			&:before {
				right: -43px;
				top: -3px;
				border-width: 41px 41px 0 0;
			}

			p {
				font-size: 10px;
			}
		}
	}

	div.section-gtechniq {
		&.section-gtechniq-subpage {
			.gtechniq-flexbox {
				.flexbox-description {
					h2 {
						font-size: 16px;
					}
				}

				.flexbox-image {
					min-width: 100px;
					max-width: 100px;
				}
			}
		}
	}

	div.section-car-wash {
		.car-wash-flexbox {
			.flexbox-cta {
				width: 100%;

				.img-absolute {
					opacity: 0.2;
				}

				.cta-description {
					text-align: center;
					width: 100%;
				}
			}
		}
	}

	div.oferta-section-content {
		.content-flexbox {
			.flexbox-body {
				.products-flexbox {
					.flexbox-service {
						width: 47%;

						.name {
							margin-top: 15px;
							font-size: 16px;
						}

						.more {
							margin-top: 10px;
						}
					}
				}
			}
		}
	}

	div.autodetailing-section-content {
		.heading-iframe {
			.heading-iframe-service {
				width: 100%;
			}
		}
	}
}