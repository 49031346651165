@import url('https://fonts.googleapis.com/css?family=Oxygen:300,400,700&subset=latin-ext');

body {
	min-height: 100vh;
	font-family: 'Oxygen', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	ul, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none !important;
	}

	blockquote {
		margin: 15px 0;
		border-color: @color1;
		font-size: 16px;
	}

	.btn {
		border-radius: 20px;
		padding: 9px 28px;
		font-size: 13px;
		font-weight: 300;
		transition: all 0.3s;
		white-space: normal;

		&.btn-default {
			border: 1px solid @color1;
			color: @color1;

			&:hover {
				background: @color1;
				color: #fff;
			}

			&.btn-filled {
				background: @color1;
				color: #fff;

				&:hover {
					background: darken(@color1, 10%);
					border-color: darken(@color1, 10%);
				}
			}
		}
	}

	.ck-editor-text {
		p {
			margin-top: 20px;
		}

		ul, ol {
			margin-top: 20px;

			li {
				display: flex;
				margin-bottom: 10px;

				&:before {
					content: "• ";
					color: @color1;
					margin-right: 10px;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		*:first-child {
			margin-top: 0;
		}
	}

	.m-t-5 { margin-top: 5px; }
	.m-t-10 { margin-top: 10px; }
	.m-t-15 { margin-top: 15px; }
	.m-t-20 { margin-top: 20px; }
	.m-t-25 { margin-top: 25px; }
	.m-t-30 { margin-top: 30px; }
	.m-b-5 { margin-bottom: 5px; }
	.m-b-10 { margin-bottom: 10px; }
	.m-b-15 { margin-bottom: 15px; }
	.m-b-20 { margin-bottom: 20px; }
	.m-b-25 { margin-bottom: 25px; }
	.m-b-30 { margin-bottom: 30px; }
	.p-t-5 { padding-top: 5px; }
	.p-t-10 { padding-top: 10px; }
	.p-t-15 { padding-top: 15px; }
	.p-t-20 { padding-top: 20px; }
	.p-t-25 { padding-top: 25px; }
	.p-t-30 { padding-top: 30px; }
	.p-b-5 { padding-bottom: 5px; }
	.p-b-10 { padding-bottom: 10px; }
	.p-b-15 { padding-bottom: 15px; }
	.p-b-20 { padding-bottom: 20px; }
	.p-b-25 { padding-bottom: 25px; }
	.p-b-30 { padding-bottom: 30px; }
}

.system-alerts {
	position: fixed;
	top: 30px;
	width: 100%;
	z-index: 999;
	text-align: center;

	.alert {
		background: rgba(228, 37, 37, 0.75);
		border-radius: 0;
		border: none;

		p {
			color: #fff;
		}

		button {
			color: #fff;
			opacity: 1;
			font-weight: 300;
		}
	}
}

.breadcrumb {
	background: none;
	padding: 0;

	li {
		font-size: 11px;

		a {
			color: @color1;
		}
	}
}

.button-configurator {
	display: flex;
	align-items: center;

	.configurator-image {
		height: 71px;
		width: 71px;
		border-radius: 50%;
		background: @color1;
		display: flex;
		border: 3px solid #d3d3d3;
		align-items: center;
		justify-content: center;

		img {
			width: 40px;
		}
	}

	.configurator-text {
		position: relative;
		margin-left: -9px;
		padding: 10px 25px 10px 25px;
		border: 3px solid #d3d3d3;
		border-left: none;
		background: @color1;

		&:after {
			content: '';
			position: absolute;
			right: -36px;
			top: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 38px 38px 0 0;
			border-color: @color1 transparent transparent transparent;
		}

		&:before {
			content: '';
			position: absolute;
			right: -46px;
			top: -3px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 43px 43px 0 0;
			border-color: #d3d3d3 transparent transparent transparent;
		}

		p {
			margin-top: 0;
			color: #fff;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 13px;
		}
	}
}

.facebook-plugin {
	position: fixed;
	right: -300px;
	top: 0;
	height: 100%;
	z-index: 111;
	display: flex;
	align-items: center;
	transition: 0.4s;

	.plugin-service {
		display: flex;
		align-items: center;

		.service-button {
			cursor: pointer;

			img {
				height: 100px;
				border-radius: 7px 0 0 7px;
			}
		}

		.service-box {
			width: 300px;
		}
	}

	&.hover {
		right: 0;
	}
}

	.pagination-nav {
		margin-top: 45px;
		display: flex;
		justify-content: center;

		li {
			a {
				margin: 5px;
				color: @color1;
				font-size: 13px;
				height: 30px;
				width: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 2px;
			}

			&.active {
				a {
					color: #fff;
					background: @color1;
				}
			}
		}
	}

div.section-header {
	padding: 30px 0;
	position: relative;
	z-index: 555;

	.navbar {
		min-height: auto;
		margin-bottom: 0;
		background: none;
		border: none;
		border-radius: 0;

		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;

			&:before, &:after {
				content: none;
			}
		}

		.navbar-brand {
			display: flex;
			align-items: center;
			padding: 0 0 0 15px;
			height: auto;

			img {
				height: 50px;
			}
		}

		.navbar-collapse {
			padding-left: 0;
			padding-right: 0;

			.navbar-nav {
				li {
					height: 60px;

					a, button {
						height: 100%;
						padding: 0 15px;
						border: none;
						background: none;
						text-transform: uppercase;
						color: #414141;
						font-size: 14px;
						font-weight: 300;
						display: flex;
						align-items: center;
						justify-content: center;
						outline: 0;
						transition: all 0.3s;
					}

					&.active, &:hover, &.open {
						a, button {
							background: #414141;
							color: #fff;
						}

						&.dropdown {
							.dropdown-menu {
								li {
									a {
										background: none;
									}
								}
							}
						}
					}

					&.dropdown {
						.dropdown-menu {
							border: none;
							border-left: 1px solid #414141;
							box-shadow: none;
							border-radius: 0;
							padding: 5px 0;
							background: #414141;

							li {
								height: auto;

								a {
									display: block;
									padding: 5px 15px;
									color: #fff;
									font-size: 12px;
								}
							}
						}
					}
				}
			}
		}

		.navbar-toggle {
			border: none;
			margin-right: 10px;
			background: none;

			.toggle-bar {
				background: #000;
				height: 2px;
				transition: all 0.4s;
				margin-bottom: 5px;
				display: block;
				width: 30px;

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			&.collapsed {
				.toggle-bar {
					&:nth-of-type(2) {
						width: 20px;
					}

					&:nth-of-type(3) {
						width: 25px;
					}

					&:nth-of-type(4) {
						width: 30px;
					}
				}
			}
		}

		.gtechniq {
			width: 150px;
		}
	}
}

div.section-main-carousel {
	margin-top: 30px;

	.pulsating-circle {
		position: absolute;
		top: 67px;
		left: -50px;
		width: 30px;
		height: 30px;
		opacity: 0.3;

		&:before {
			content: '';
			position: relative;
			display: block;
			width: 300%;
			height: 300%;
			margin-left: -100%;
			margin-top: -100%;
			border-radius: 45px;
			background-color: @color1;
			animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0; 
			top: 0;
			display: block;
			width: 100%;
			height: 100%;
			background-color: white;
			border-radius: 15px;
			animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
		}

		&.circle-small {
			width: 12px;
			height: 12px;
			opacity: 0.9;

			&:after {
				opacity: 0.5;
			}
		}
	}

	@keyframes pulse-ring {
		0% {
			transform: scale(.33);
		}
		80%, 100% {
			opacity: 0;
		}
	}

	@keyframes pulse-dot {
		0% {
			transform: scale(.8);
		}
		50% {
			transform: scale(1);
		}
		100% {
			transform: scale(.8);
		}
	}

	.main-carousel-flexbox {
		display: flex;
		align-items: center;

		.flexbox-car {
			position: relative;
			align-self: flex-start;
			width: 65%;

			svg {
				position: absolute;
				z-index: 111;
				top: 0;
				left: 0;
			}

			path {
				position: relative;
				z-index: 999;
				opacity: 1;
				fill-opacity: 0;
			}

			a {
				cursor: pointer;

				&:hover {
					.st0 {
						opacity: 0.6;
					}
				}
			}

			.svg-image-opacity {
				opacity: 0;
				transition: all 0.5s;

				&.active {
					opacity: 1;
				}
			}

			.car-image-size {
				width: 100%;
				transition: all 0.5s;

				&.active {
					opacity: 0.35;
					filter: grayscale(100);
				}
			}

			.st0 {
				transition: all 0.5s;
				opacity: 0;
				fill: transparent;
			}
		}

		.flexbox-description {
			padding-left: 50px;
			width: 30%;
			position: relative;

			.description-box {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				opacity: 0;
				transition: all 0.5s;

				&#description-box-0 {
					position: relative;
					z-index: 111;
					top: auto;
					transform: none;
				}
				
				&.active {
					opacity: 1;
				}

				h2 {
					font-weight: 300;
					position: relative;
					z-index: 111;
				}

				.nav-tabs {
					margin-top: 40px;
					border: none;
					display: flex;

					li {
						margin-right: 30px;

						a {
							padding: 0;
							margin: 0;
							border: none;
							text-transform: uppercase;
							color: #444;
							font-weight: 900;
							font-size: 12px;
							background: none;
						}

						&:hover {
							a {
								background: none;
							}
						}

						&.active {
							a {
								border: none;
								color: @color1;
							}
						}

						&:last-child {
							margin-right: 0;
						}
					}
				}

				p {
					margin-top: 30px;
					font-weight: 300;
				}

				.info-text {
					text-transform: uppercase;
					font-weight: 700;
					color: @color1;
				}

				.button-configurator {
					margin-top: 30px;

					.configurator-text {
						p {
							margin-top: 0;
						}
					}
				}

				.arrow-configurator {
					img {
						width: 23%;
						transform: translate(50%,0);
					}
				}
			}
		}
	}

	.button-configurator-wrapper {
		margin-top: 30px;
		display: none;
		justify-content: center;
	}
}

div.section-gtechniq {
	margin-top: 50px;
	border-bottom: 1px solid @color1;
	padding: 80px 0;
	background: #111;
	overflow: hidden;
	position: relative;

	#particles-js {
		position: absolute;
		width: 100%;
		height: 100%;
		background-image: url("");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		top: 0;
	}

	.gtechniq-flexbox {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.flexbox-product {
			width: 21%;
		}

		.flexbox-description {
			width: 45%;

			h2 {
				font-weight: 300;
				color: #fff;
			}

			p {
				margin-top: 30px;
				font-weight: 300;
				color: #fff;
			}

			img {
				margin-top: 30px;
				max-width: 100%;
				width: 300px;
			}
		}

		.flexbox-image {
			width: 22%;

			img {
				margin-top: 50px;
				width: 100%;

				&:nth-child(1) {
					margin-top: 0;
				}
			}
		}
	}

	&.section-gtechniq-subpage {
		margin-top: 0;
		padding: 40px 0;

		.flexbox-description {
			width: 70%;
		}
	}
}

div.section-car-wash {
	position: relative;

	.btn-more {
		position: absolute;
		display: block;
		z-index: 111;
		top: -20px;
		left: 50%;
		transform: translateX(-50%);

		img {
			height: 20px;
			margin-left: 20px;
		}
	}

	.car-wash-flexbox {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.flexbox-service {
			margin-top: 50px;
			width: 40%;

			.iframe-wrapper {
				position: relative;
				border-radius: 5px;
				width: 100%;
				height: 0;
				overflow: hidden;
				padding-bottom: 63%;

				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: none;
				}
			}
		}

		.flexbox-cta {
			width: 57%;
			overflow: hidden;
			margin-top: 50px;
			border-radius: 5px;
			padding: 30px;
			background: #f9f9f9;
			display: flex;
			align-items: center;
			position: relative;

			.img-absolute {
				position: absolute;
				right: -250px;
				opacity: 0.8;
				filter: grayscale(100);
			}

			.cta-description {
				width: 65%;

				h2 {
					font-weight: 300;
				}

				p {
					margin-top: 30px;
					font-weight: 300;
				}

				.btn {
					margin-top: 30px;
				}
			}
		}
	}
}

div.section-portfolio {
	padding: 50px 0;

	.section-heading {
		text-align: center;

		h2 {
			font-weight: 300;
			font-size: 26px;
			color: #999;

			a {
				color: #999;
				transition: all 0.3s;

				&:hover {
					color: @color1;
				}
			}

			small {
				font-size: 13px;
				color: #999;
				opacity: 0.6;
			}
		}
	}

	.portfolio-flexbox {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.flexbox-service {
			margin-top: 30px;
			width: 23%;
			text-align: center;

			.service-image-wrapper {
				width: 100%;
				padding-top: 55%;
				position: relative;

				.service-image {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					display: flex;
					align-items: center;
					overflow: hidden;
					border-radius: 5px;

					img {
						width: 100%;
					}
				}
			}

			.name {
				color: #333;
				margin-top: 20px;
				font-size: 22px;
				font-weight: 300;
			}

			.category {
				margin-top: 12px;
				font-size: 11px;
				color: #999;
			}

			.more {
				margin-top: 15px;
				color: @color1;
				font-weight: 700;
				font-size: 12px;
				text-transform: uppercase;
			}

			&.service-disabled {
				opacity: 0.15;
			}

			&.service-hidden {
				margin: 0;
				padding: 0;
				visibility: hidden;
			}
		}

		.flexbox-single-realization {
			margin-top: 30px;
			width: 49.33%;

			.lSSlide {
				li {
					position: relative;

					&:before {
						content: '';
						height: 100%;
						width: 2px;
						background: #fff;
						top: 0;
						left: -1px;
						position: absolute;
					}

					&:after {
						content: '';
						height: 100%;
						width: 2px;
						background: #fff;
						top: 0;
						right: -1px;
						position: absolute;
					}
				}
			}

			.lSGallery {
				li {
					height: 80px;
					border-radius: 5px;
					overflow: hidden;
					display: flex;
					align-items: center;

					img {
						opacity: 0.7;
						transition: all 0.3s;
					}

					&.active, &:hover {
						img {
							opacity: 1;
						}
					}
				}
			}
			
			img {
				width: 100%;
				border-radius: 5px;
			}

			h2 {
				margin-top: 30px;
				font-weight: 300;
				text-align: center;
			}

			.btn {
				margin-top: 30px;
				width: 100%;
			}

			p {
				margin-top: 30px;
				font-weight: 300;
			}
		}
	}
}

div.certyfikaty-section-content {
	padding: 50px 0;

	.section-heading {
		text-align: center;

		h2 {
			font-weight: 300;
			font-size: 26px;
			color: #999;

			small {
				font-size: 13px;
				color: #999;
				opacity: 0.6;
			}
		}
	}

	.content-flexbox {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.flexbox-service {
			margin-top: 30px;
			width: 23%;
			text-align: center;
			background: #f9f9f9;

			.service-image-wrapper {
				width: 100%;
				padding-top: 55%;
				position: relative;

				.service-image {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					display: flex;
					align-items: center;
					overflow: hidden;
					border-radius: 5px;

					img {
						width: 100%;
					}
				}
			}

			&.service-hidden {
				margin: 0;
				padding: 0;
				visibility: hidden;
			}
		}
	}
}

div.oferta-section-content {
	padding: 50px 0;

	.section-heading {
		text-align: center;

		h2 {
			font-weight: 300;
			font-size: 26px;
			color: #999;

			small {
				font-size: 13px;
				color: #999;
				opacity: 0.6;
			}
		}
	}

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-body {
			margin-top: 50px;
			width: 62%;

			p {
				font-weight: 300;
			}

			.products-flexbox {
				margin-top: 20px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.flexbox-service {
					margin-top: 30px;
					width: 30%;
					text-align: center;

					.service-image-wrapper {
						width: 100%;
						padding-top: 56%;
						position: relative;

						.service-image {
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							width: 100%;
							display: flex;
							align-items: center;
							overflow: hidden;
							border-radius: 5px;

							img {
								width: 100%;
							}
						}
					}

					.name {
						color: #333;
						margin-top: 20px;
						font-size: 22px;
						font-weight: 300;
					}

					.more {
						margin-top: 15px;
						color: @color1;
						font-weight: 700;
						font-size: 12px;
						text-transform: uppercase;
					}

					&.service-hidden {
						margin: 0;
						padding: 0;
						visibility: hidden;
					}
				}
			}
		}

		.flexbox-sidebar {
			margin-top: 50px;
			width: 30%;

			.heading {
				font-size: 16px;
				font-weight: 700;
				text-transform: uppercase;
				margin-bottom: 30px;
			}

			ul {
				margin-top: 30px;
				padding-left: 15px;

				li {
					display: flex;
					margin-bottom: 10px;

					&:before {
						content: "• ";
						color: @color1;
						margin-right: 10px;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	&.kategoria {
		.content-flexbox {
			.flexbox-body {	
				.products-flexbox {
					.flexbox-service {
						.service-image-wrapper {
							padding-top: 100%;
						}

						.name {
							font-size: 16px;
						}
					}
				}
			}

			.flexbox-sidebar {
				.sidebar-button {
					display: none;
					width: 100%;
				}

				.sidebar-button-close {
					position: absolute;
					display: none;
					width: 100%;
					top: 20px;
					left: 0;

					.container {
						display: flex;
						justify-content: flex-end;

						&:before, &:after {
							content: none;
						}
					}

					button {
						outline: none;
						border: none;
						background: none;
						padding: 15px;
						display: flex;
						justify-content: center;
						align-items: center;

						.menu-bar {
							display: block;
							position: absolute;
							width: 30px;
							height: 2px;
							background: #222;

							&:nth-child(1) {
								transform: rotate(45deg);
							}

							&:nth-child(2) {
								transform: rotate(-45deg);
							}
						}
					}
				}

				.category-nav {
					padding-left: 0;

					li {
						a {
							color: #333;
							font-size: 15px;
						}

						&:hover {
							a {
								color: @color1;
							}
						}

						&.active {
							a {
								color: @color1;
								font-weight: 700;
							}
						}
					}
				}
			}
		}
	}
}

div.produkt-section-content {
	padding: 50px 0;
	position: relative;
	overflow: hidden;

	.content-background {
		position: absolute;
		clip-path: polygon(30% 0, 70% 0, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0 70%, 0 30%);
		background: lighten(@gray-lighter, 3%);
		height: 400px;
		width: 400px;
		bottom: -50px;
		right: 100px;
	}

	.section-heading {
		text-align: center;

		h2 {
			font-weight: 300;
			font-size: 26px;
			color: #999;

			a {
				color: #999;
				transition: all 0.3s;

				&:hover {
					color: @color1;
				}
			}

			small {
				font-size: 13px;
				color: #999;
				opacity: 0.6;
			}
		}
	}

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-sidebar {
			margin-top: 50px;
			min-width: 420px;
			max-width: 420px;
			height: 420px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;

			img {
				max-height: 80%;
				width: auto;
				position: relative;
			}

			&:before {
				content: '';
				position: absolute;
				clip-path: polygon(30% 0, 70% 0, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0 70%, 0 30%);
				background: lighten(@gray-lighter, 4%);
				height: 100%;
				width: 100%;
			}
		}

		.flexbox-body {
			margin-top: 50px;
			width: 100%;
			padding-left: 100px;

			h2 {
				font-size: 26px;
				color: #333;
			}

			.ck-editor-text {
				margin-top: 30px;

				h3 {
					font-size: 16px;
					font-weight: 600;
					color: @color1;
				}
			}
		}
	}

	.description-flexbox {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.ck-editor-text {
			width: 45%;

			h3 {
				font-size: 16px;
				font-weight: 600;
				color: @color1;
			}

			&:nth-child(1) {
				margin-top: 60px;
			}

			&:nth-child(2) {
				margin-top: 30px;
				padding: 30px;
				border: 1px solid @color1;
			}
		}
	}
}

div.autodetailing-section-content {
	padding: 50px 0;

	.section-heading {
		text-align: center;

		h2 {
			font-weight: 300;
			font-size: 26px;
			color: #999;

			small {
				font-size: 13px;
				color: #999;
				opacity: 0.6;
			}
		}
	}

	.heading-iframe {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.heading-iframe-service {
			margin-top: 50px;
			width: 45%;

			.iframe-wrapper {
				position: relative;
				border-radius: 8px;
				width: 100%;
				height: 0;
				overflow: hidden;
				padding-bottom: 56.25%;

				iframe {
					border-radius: 8px;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: none;
				}
			}
		}
	}

	.panel-group {
		margin: 50px 0;

		.panel {
			border: none;
			background: none;
			margin-top: 15px;
			box-shadow: none;

			.panel-heading {
				background: none;
				padding: 0;

				h3 {
					a {
						display: flex;
						align-items: center;
						position: relative;

						.name {
							font-size: 15px;
							color: @color1;
							text-transform: uppercase;
						}

						.name-toggle {
							padding-right: 30px;

							&:before, &:after {
								content: "";
								position: absolute;
								left: 0;
								width: 7px;
								height: 1px;
								background: @color1;
								transition: all 0.3s;
							}

							&:after {
								transform: rotate(90deg);
							}
						}
					}
				}

				&.actived {
					h3 {
						a {
							.name {
							}

							.name-toggle {
								&:after {
									transform: rotate(0deg);
								}
							}
						}
					}
				}
			}

			.panel-body {
				border: none;
				padding: 15px 0 0 0;

				p {
					font-size: 14px;
					font-weight: 300;
				}
			}

			&:first-child {
				margin-top: 0;
			}
		}
	}

	.content-description {
		margin-top: 50px;

		p {
			font-size: 14px;
			font-weight: 300;
		}

		img {
			max-width: 100%;
			height: auto !important;
		}

		.content-flexbox {
			display: flex;
			justify-content: space-between;

			.flexbox-service {
				width: 30%;
				margin-top: 30px;

				p {
					margin-top: 20px;
				}
			}
		}
	}

	.media-flexbox {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.flexbox-service {
			margin-top: 30px;
			width: 30%;

			.iframe-wrapper {
				position: relative;
				border-radius: 8px;
				width: 100%;
				height: 0;
				overflow: hidden;
				padding-bottom: 56.25%;

				iframe {
					border-radius: 8px;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: none;
				}
			}
		}
	}

	.content-buttons {
		margin-top: 30px;
		display: flex;
		flex-wrap: wrap;

		.btn {
			margin-right: 20px;
		}
	}
}

div.cennik-section-content {
	padding: 50px 0;

	.section-heading {
		text-align: center;

		h2 {
			font-weight: 300;
			font-size: 26px;
			color: #999;

			small {
				font-size: 13px;
				color: #999;
				opacity: 0.6;
			}
		}
	}

	.table-responsive {
		margin-top: 30px;

		.table {
			tbody {
				tr {
					th {
						border: none;
						font-size: 13px;
						color: #333;
						vertical-align: middle;

						small {
							font-size: 11px;
							font-weight: 400;
						}
					}

					td {
						border: none;
						font-size: 15px;
						font-weight: 300;
						vertical-align: middle;
					}

					&:nth-child(even) {
						background: #f9f9f9;
					}
				}
			}
		}
	}
}

div.myjnia-section-content {
	padding: 50px 0;

	.section-heading {
		text-align: center;

		h2 {
			font-weight: 300;
			font-size: 26px;
			color: #999;

			small {
				font-size: 13px;
				color: #999;
				opacity: 0.6;
			}
		}
	}

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-body {
			margin-top: 50px;
			width: 62%;

			p {
				font-size: 14px;
				font-weight: 300;
			}
		}

		.flexbox-sidebar {
			margin-top: 50px;
			width: 30%;

			.sidebar-image {
				img {
					margin-top: 30px;
					width: 100%;
					border-radius: 5px;

				}

				&:first-child {
					img {
						margin-top: 0;
					}
				}
			}
		}
	}

	.owl-carousel {
		margin-top: 50px;

		.owl-controls {
			display: none;
		}
	}
}

div.bezpieczne-mycie-section-content {
	padding: 50px 0;
	position: relative;
	overflow: hidden;

	.content-background {
		position: absolute;
		clip-path: polygon(30% 0, 70% 0, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0 70%, 0 30%);
		background: lighten(@gray-lighter, 3%);
		height: 400px;
		width: 400px;
		bottom: -50px;
		right: 100px;
	}

	.owl-carousel {
		.item {
			.step {
				margin-bottom: 30px;
				margin-left: -32px;
				display: flex;
				align-items: center;

				.step-inner {
					clip-path: polygon(30% 0, 70% 0, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0 70%, 0 30%);
					background: #ED213A;  /* fallback for old browsers */
					background: -webkit-linear-gradient(to right, #93291E, #ED213A);  /* Chrome 10-25, Safari 5.1-6 */
					background: linear-gradient(to right, #93291E, #ED213A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
					height: 110px;
					min-width: 110px;
					max-width: 110px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;

					p {
						color: #fff;
						font-weight: 700;
						font-size: 18px;

						b {
							font-size: 30px;
						}
					}
				}

				button {
					margin-left: 30px;
					background: #dd1e36;
					padding: 15px 20px;
					display: flex;
					align-items: center;
					color: #fff;
					border: none;
					outline: none;
					text-transform: uppercase;

					img {
						height: 20px;
						min-width: 20px;
						max-width: 20px;
						margin-right: 20px;
					}
				}
			}

			.slider-content {
				display: flex;
				align-items: center;
				justify-content: space-between;

				.content-image {
					min-width: 420px;
					max-width: 420px;
					height: 420px;
					clip-path: polygon(30% 0, 70% 0, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0 70%, 0 30%);
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;

					img {
						max-height: 80%;
						width: auto;
					}

					&:before {
						content: '';
						position: absolute;
						clip-path: polygon(30% 0, 70% 0, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0 70%, 0 30%);
						background: lighten(@gray-lighter, 1%);
						height: 100%;
						width: 100%;
					}

					.cta {
						position: absolute;
						bottom: 10px;
						text-transform: uppercase;
						font-size: 9px;
						font-weight: 700;
						color: @color1;
					}

					&.background {
						clip-path: polygon(30% 0, 70% 0, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0 70%, 0 30%);

						img {
							width: 100%;
							max-height: 100%;
						}

						&:before {
							content: none;
						}
					}
				}

				.content-description {
					width: 100%;
					padding-left: 100px;

					p {
						font-weight: 300;
					}

					.time {
						margin-top: 30px;
						border: 1px solid @color1;
						padding: 20px;
						display: flex;
						align-items: center;
						justify-content: space-around;

						img {
							height: 40px;
							min-width: 40px;
							max-width: 40px;
						}

						p {
							width: 100%;
							padding-left: 20px;

							span {
								font-weight: 700;
								color: @color1;
								white-space: nowrap;
							}
						}
					}
				}
			}
		}

		.owl-controls {
			.owl-nav {
				margin-top: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				justify-content: space-between;

				.owl-prev, .owl-next {
					background: @color1;
					display: flex;
					align-items: center;
					font-size: 10px;
					text-transform: uppercase;
					margin: 0;
					padding: 10px;

					img {
						height: 10px;
					}
				}

				.owl-prev {
					img {
						margin-right: 10px;
					}
				}

				.owl-next {
					img {
						margin-left: 10px;
					}
				}
			}

			.owl-dots {
				margin-top: 30px;
				display: flex !important;
				justify-content: space-between;
				counter-reset : section;

				.owl-dot {
					padding: 10px 0;
					display: block;
					width: 8%;

					span {
						display: block;
						width: 100%;
						height: 2px;
						border-radius: 0;
						margin: 0;
					}

					&:before {
						font-size: 10px;
						color: #999;
						content: "krok " counter(section, decimal);
						counter-increment : section;  
						padding-bottom: 5px;
						display: block;
					}

					&.active {
						span {
							background: @color1;
						}

						&:before {
							color: @color1;
						}
					}
				}
			}
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 500px;
			max-width: 100%;
			padding: 25px;
			position: relative;

			.service-button {
				position: absolute;
				top: 20px;
				right: 20px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				.button-bar {
					display: block;
					position: absolute;
					width: 20px;
					height: 2px;
					background: #222;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
			}

			.service-content {
				padding-right: 40px;

				.heading {
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					margin-bottom: 10px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				.btn-link {
					padding: 0;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
				}
			}
		}
	}
}

div.kontakt-section-content {
	iframe {
		margin-top: 50px;
		height: 350px;
		width: 100%;
		border: none;
	}

	.content-flexbox {
		padding-bottom: 50px;
		display: flex;
		justify-content: space-between;

		.flexbox-service {
			margin-top: 50px;
			white-space: nowrap;

			h1 {
				font-weight: 300;
				font-size: 32px;
				position: relative;
				z-index: 111;
			}

			p {
				font-size: 18px;
				margin-top: 30px;
				font-weight: 300;
			}

			a {
				color: @color1;
				font-size: 18px;
			}
		}

		.flexbox-form {
			margin-top: 50px;
			padding-left: 100px;
			width: 100%;

			label {
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 300;
				color: #999;
			}

			input {
				box-shadow: none;
				border: 1px solid #e9e9e9;
				border-radius: 4px;
				height: 40px;
			}

			textarea {
				box-shadow: none;
				border: 1px solid #e9e9e9;
				border-radius: 4px;
			}

			.btn-link {
				font-weight: 700;
				font-size: 18px;
				text-decoration: none;
				color: #000;
			}
		}
	}
}

div.konfigurator-section-content {
	padding-bottom: 50px;

	.content-summary-box {
		position: fixed;
		right: 0;
		top: 100px;
		padding: 30px;
		background: #414141;
		opacity: 0.5;
		transition: all 0.3s;
		border-radius: 5px 0 0 5px;
		text-align: right;
		z-index: 111;
		border-right: 2px solid @color1;
		max-width: 350px;

		.heading {
			color: #fff;
			opacity: 0.5;
			font-weight: 300;
		}

		.point {
			margin-top: 20px;
			color: #fff;
		}

		.text {
			margin-top: 20px;
			color: #fff;
			font-size: 17px;
		}

		.btn {
			width: 100%;
			margin-top: 20px;
		}

		&:hover {
			opacity: 1;
		}
	}

	.section-heading {
		margin-top: 50px;
		text-align: center;

		h2 {
			font-weight: 300;
			font-size: 26px;
			color: #999;

			small {
				font-size: 13px;
				color: #999;
				opacity: 0.6;
			}
		}
	}

	.type-flexbox {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.flexbox-service {
			margin-top: 30px;
			width: 23%;
			text-align: center;
			cursor: pointer;

			.service-inner {
				opacity: 0.5;
				display: block;
				border-top: 2px solid transparent;
				transition: all 0.3s;

				&:hover {
					opacity: 1;
				}
			}

			input {
				visibility: hidden;
				position: absolute;
			}

			img {
				max-width: 100%;
			}

			.name {
				color: #333;
				margin-top: 20px;
				font-size: 22px;
				font-weight: 300;
				transition: all 0.3s;
			}

			.more {
				margin-top: 15px;
				color: @color1;
				font-weight: 700;
				font-size: 12px;
				text-transform: uppercase;
			}

			.text {
				margin-top: 15px;
				font-weight: 300;
				font-size: 13px;
			}

			.price {
				background: @color1;
				padding: 5px;
				border-radius: 4px;
				margin-top: 15px;
				color: #fff;
				font-weight: 700;
				font-size: 12px;
				text-transform: uppercase;
			}
		}

		.flexbox-service > input:checked + .service-inner {
			opacity: 1;

			.name {
				font-weight: 700;
			}
		}

		.flexbox-service > input:disabled + .service-inner {
			opacity: 0.1;
			cursor: default;
		}

		&.type-flexbox-products {
			.flexbox-service {
				width: 13%;
					
				.service-inner {
					height: auto;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.header {
						min-height: 230px;
					}

					img {
						min-height: 155px;
					}

					.name {
						font-size: 13px;
						min-height: 54px;
					}

					.more {
						min-height: 34px;
					}
				}

				.service-advantages {
					margin-top: 15px;
					display: block;

					p {
						margin-top: 10px;
						font-size: 11px;
						font-weight: 300;
					}

					.stars {
						display: flex;
						justify-content: center;

						i {
							margin: 3px;
							font-size: 14px;
						}

						&.red {
							i {
								color: #df1919;
							}
						}
					}
				}
			}
		}
	}

	.addition-flexbox {
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
		box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
		border-radius: 4px;
		overflow: hidden;

		.name {
			font-weight: 700;
			font-size: 16px;
		}

		.text {
			font-weight: 300;
		}

		.flexbox-image {
			width: 15%;

			img {
				width: 100%;
			}
		}

		.flexbox-price {
			padding: 20px;
			width: 35%;

			.label-price {
				margin-top: 15px;
				display: block;

				.label-price-inner {
					opacity: 0.5;
					display: flex;
					align-items: center;
					transition: all 0.3s;
					font-weight: 300;
					font-size: 16px;
					cursor: pointer;

					strong {
						color: @color1;
						margin-right: 10px;
					}

					&:before {
						display: block;
						content: '';
						height: 8px;
						width: 8px;
						border-radius: 50%;
						background: @color1;
						opacity: 0.2;
						transition: all 0.3s;
						margin-right: 10px;
					}

					&:hover {
						opacity: 1;

						&:before {
							opacity: 0.4;
						}
					}
				}

				input {
					visibility: hidden;
					position: absolute;
				}

			}

			.label-price > input:checked + .label-price-inner {
				opacity: 1;

				&:before {
					opacity: 1;
				}
			}
		}

		.flexbox-description {
			padding: 20px;
			width: 45%;
		}
	}

	.btn-100 {
		width: 100%;
		margin-top: 30px;
	}
}

div.konfigurator-podsumowanie-section-content {
	.content-flexbox {
		padding-bottom: 50px;
		display: flex;
		justify-content: space-between;

		.flexbox-service {
			margin-top: 50px;
			white-space: nowrap;

			h1 {
				font-weight: 300;
				font-size: 32px;
				position: relative;
				z-index: 111;
			}

			p {
				font-size: 18px;
				margin-top: 20px;
				font-weight: 300;

				strong {
					color: @color1;
				}
			}

			a {
				color: @color1;
				font-size: 18px;
			}
		}

		.flexbox-form {
			margin-top: 50px;
			padding-left: 100px;
			width: 100%;

			label {
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 300;
				color: #999;
			}

			input {
				box-shadow: none;
				border: 1px solid #e9e9e9;
				border-radius: 4px;
				height: 40px;
			}

			textarea {
				box-shadow: none;
				border: 1px solid #e9e9e9;
				border-radius: 4px;
			}

			.btn {
				width: 100%;
			}
		}
	}
}

div.section-footer {
	padding-top: 50px;
	background: #e9e9e9;

	.footer-flexbox {
		margin-bottom: 30px;
		display: flex;
		justify-content: space-between;

		.flexbox-service {
			&.info {
				max-width: 300px;

				img {
					width: 200px;
				}

				p {
					margin-top: 20px;
					font-weight: 300;
					color: #777;
				}
			}

			ul {
				li {
					margin-bottom: 5px;

					.heading {
						font-size: 16px;
						color: @color1;
						font-weight: 300;
						margin-bottom: 10px;
					}

					a {
						color: #777;
						font-weight: 300;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		.button-configurator {
			padding-right: 40px;
		}
	}

	.alert {
		text-align: center;
		background: none;
		border: 1px solid lighten(@gray-light, 40%);
		padding: 20px;
		margin-bottom: 0;

		p {
			font-weight: 300;
			color: #777;
			font-size: 12px;

			a {
				color: @color1;
			}
		}
	}

	.footer-credits {
		padding: 20px 0;
		margin-top: 20px;
		text-align: center;

		p {
			color: #777;
			font-weight: 300;
			font-size: 13px;

			a {
				color: @color1;
			}
		}
	}
}